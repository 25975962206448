import React, { useState, useEffect } from "react";
import "./Style.css";
import UpcomingProducts from "./UpcomingProducts";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import "./Modal.css";
import { Rating } from "@mui/material";
import { Grid, Typography } from "@material-ui/core";
//import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import LatestProducts from "./LatestProducts";
import LastOrders from "./LastOrders";
import Header from "./Header";
import { strings } from "../../string.js";
import { connect } from "react-redux";
import { logout } from "../../actions/authAction";
import { Link, withRouter } from "react-router-dom";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';


const useStyles = makeStyles({
  card: {
    backgroundColor: "#fff",
    borderRadius: "5px",
    marginBottom: "10px",
    padding: "25px",
    boxShadow: "0 1.2rem 3.2rem rgba(0,0,0,0.3)",
  },
});

const Dashboard = ({ history, server, access_token, login, role, auth, logout }) => {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const classes = useStyles();

  const [tabIndex, setTabIndex] = React.useState("0");

  const handleChange = (e, index) => {

    setTabIndex(index)

    if (index == 0) {
      history.push('/dashboard')
    }

    if (index == 1) {
      history.push('/new-order')
    }

    if (index == 2) {
      history.push('/cart')
    }

    if (index == 3) {
      history.push('/past-orders')
    }

    if (index == 4) {
      history.push('/account')
    }

  }

  return (
    <>

      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

        <Header />


        <div className="only-desktop" style={{
          flexDirection: 'row', marginTop: 60, paddingTop:5, 
          position: 'fixed', top: 0, left: 0, right: 0, zIndex: 8000, backgroundColor: '#FCF5E5',
        }}>

          <Link to="/dashboard" style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center',
            width: '20%', borderRadius: 0, textDecoration: 'none',
            height: 60, borderBottom: '4px solid #F05225', cursor: 'pointer',
            alignSelf: 'center'
          }}>

            <span style={{
              width: '100%', textAlign: 'center', color: '#F05225',
              fontSize: 15, textTransform: 'uppercase', fontWeight: 700
            }}>{strings.dashboard}</span>
          </Link>

          <Link to="/new-order" style={{
            display: 'flex', textDecoration: 'none', borderRadius: 0,
            flexDirection: 'row', cursor: 'pointer', alignItems: 'center', width: '20%', height: 60,
            alignSelf: 'center', color: '#000000',
            borderBottom: '2px solid grey',
          }}>

            <span style={{
              width: '100%', textAlign: 'center',
              fontSize: 15, textTransform: 'uppercase', fontWeight: 700
            }}>{strings.new_order}</span>
          </Link>

          <Link to="/cart" style={{
            display: 'flex', textDecoration: 'none', borderRadius: 0, flexDirection: 'row', cursor: 'pointer', alignItems: 'center', width: '20%', height: 60,
            alignSelf: 'center', color: '#000000', borderBottom: '2px solid grey'
          }}>

            <span style={{
              width: '100%', textAlign: 'center',
              fontSize: 15, textTransform: 'uppercase', fontWeight: 700
            }}>{strings.cart}</span>
          </Link>

          <Link to="/past-orders" style={{
            display: 'flex', borderRadius: 0, textDecoration: 'none',
            flexDirection: 'row', cursor: 'pointer', alignItems: 'center', width: '20%', height: 60,
            alignSelf: 'center', color: '#000000', borderBottom: '2px solid grey'
          }}>

            <span style={{
              width: '100%', textAlign: 'center',
              fontSize: 15, textTransform: 'uppercase', fontWeight: 700
            }}>{strings.past_orders}</span>
          </Link>

          <Link to="/account" style={{
            display: 'flex', borderRadius: 0, textDecoration: 'none',
            flexDirection: 'row', cursor: 'pointer', alignItems: 'center', width: '20%', height: 60,
            alignSelf: 'center', color: '#000000', borderBottom: '2px solid grey'
          }}>

            <span style={{
              width: '100%', textAlign: 'center',
              fontSize: 15, textTransform: 'uppercase', fontWeight: 700
            }}>{strings.account}</span>
          </Link>


        </div>

        <Grid container className="homePage">
          <Grid xs={12}>
            <Box className={classes.card} style={{ flexDirection: 'column',}}>
              <h2 style={{ marginLeft: "1.5%" }}>
                {strings.last_3_orders}
                <LastOrders access_token={access_token} history={history} />
              </h2>
            </Box>
          </Grid>
          <Grid xs={12} style={{}}>
            <Box className={classes.card} style={{ flexDirection: 'column', backgroundColor:'#f0f0f0'  }}>
              <h2 style={{ marginLeft: '1.5%' }}>
                {strings.latest_products}
              </h2>
              <LatestProducts access_token={access_token} />
            </Box>
          </Grid>
          <Grid xs={12} style={{ marginBottom: 50 }}>
            <Box className={classes.card} style={{ flexDirection: 'column', 
            backgroundColor:'#f0f0f0' }}>
              <h2 style={{ marginLeft: "1.5%" }}>
                {strings.upcoming_products}
              </h2>
              <UpcomingProducts handleOpen={handleOpen} access_token={access_token} />
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: { xs: "block", md: "none", lg: "none" },
          }}
        >
          <BottomNavigation
            sx={{
              width: "100%",
              position: "fixed",
              bottom: 0,
              zIndex: 1000,
              backgroundColor: '#f0f0f0',
              left: 0,
              right: 0,
            }}
            value={"0"}
            showLabels
            onChange={handleChange}
            elevation={3}
          >
            <BottomNavigationAction
              label="DASHBOARD"
              value="0"
              sx={{fontSize:35, fontWeight:700}}
              icon={<DashboardIcon/>}
            />
            <BottomNavigationAction
              label="NEW ORDER"
              value="1"
              sx={{fontSize:35, fontWeight:700}}
              icon={<AddBoxIcon />}
            />
            <BottomNavigationAction
              label="CART"
              value="2"
              sx={{fontSize:35, fontWeight:700}}
              icon={<ShoppingCartIcon />}
            />
            <BottomNavigationAction
              label="ORDERS"
              value="3"
              sx={{fontSize:35, fontWeight:700}}
              icon={<ShoppingBagIcon />}
            />
            

          </BottomNavigation>
        </Box>

      </div>

    </>
  );
};

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
  point: state.auth.point,
});

export default connect(mapStateToProps, { logout })(withRouter(Dashboard));