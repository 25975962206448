import React from 'react';
import "./New.css";


const ConfirmationDialog = ({ isOpen, message, imageSrc, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return (
    <div className="confirmation-dialog">
      <img src={imageSrc} alt="Confirmation Image" style={{marginTop:20, width:50}}/>
      <p dangerouslySetInnerHTML={{ __html: message }}></p>
      <button onClick={onConfirm} style={{padding:10, backgroundColor:'green', color:'white', fontSize:17, borderWidth:0, width:100, borderRadius:10}}>Yes</button>
      <button onClick={onCancel} style={{padding:10, backgroundColor:'red', color:'white', fontSize:17, borderWidth:0, width:100, borderRadius:10}}>No</button>
    </div>
  );
};

export default ConfirmationDialog;