import React, { useState, useEffect } from "react";
import "./New.css";
import Button from "@mui/material/Button";
import Neworder2 from "./Neworder2";
import Neworder3 from "./Neworder3";
import { strings } from "../../string.js";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../Home/Header";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { Breathing } from "react-shimmer";
import { isMobile } from 'react-device-detect';


const customStyle = {
  backgroundColor: "#FBF8F1",
  width: isMobile?'80%':"60%",
  margin: "auto",
  padding: "10px",
  justifyContent: "flex-start",
  border: "2px solid #EEEEEE",
  textAlign: "flex-start",
  boxShadow: "0 1.4rem 3.8rem rgba(0, 0, 0, 0.075)",
}

const customStyle1 = {
  backgroundColor: "#ebedf8",
  width: isMobile?'80%':"60%",
  margin: "auto",
  padding: "10px",
  justifyContent: "center",
  border: "2px solid #EEEEEE",
  textAlign: "center",
  boxShadow: "0 1.4rem 3.8rem rgba(0, 0, 0, 0.075)",
}

const Neworder = ({ history, server, access_token, login, role, auth, logout }) => {

  const [disclaimer, setDisclaimer] = useState(false);  

  const [isCategoriesVisible, setIsCategoriesVisible] = useState(true);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState([]);
  const [searchKey, setSearchKey] = useState('')
  const [currentCategoryId, setCurrentCategoryId] = React.useState(2);
  const [currentCategory, setCurrentCategory] = useState(null)

  const [disclaimerText1, setDisclaimerText1] = useState('')  
  const [disclaimerText2, setDisclaimerText2] = useState('') 
  const [disclaimerText3, setDisclaimerText3] = useState('') 
  const [disclaimerText4, setDisclaimerText4] = useState('') 
  const [disclaimerText5, setDisclaimerText5] = useState('') 
  const [disclaimerText6, setDisclaimerText6] = useState('') 
  const [disclaimerText7, setDisclaimerText7] = useState('') 
  const [disclaimerText8, setDisclaimerText8] = useState('') 
  const [disclaimerText9, setDisclaimerText9] = useState('') 
  const [disclaimerText10, setDisclaimerText10] = useState('') 

  const [tabIndex, setTabIndex] = React.useState("0");

  const handleChange = (e, index) => {
    setTabIndex(index)

    if (index == 0) {
      history.push('/dashboard')
    }

    if (index == 1) {
      history.push('/new-order')
    }

    if (index == 2) {
      history.push('/cart')
    }

    if (index == 3) {
      history.push('/past-orders')
    }

    if (index == 4) {
      history.push('/account')
    }

  }

  const handleAccept = () => {
    setDisclaimer(true);
    localStorage.setItem('disclaimer_accpeted', 'yes');
  };

  useEffect(() => {

    getCategories();
    getDisclaimerText();

    var disclaimerAccepted = localStorage.getItem('disclaimer_accpeted');

    if (disclaimerAccepted == null) {
      setDisclaimer(false);
    } else {
      setDisclaimer(true);
    }

  }, []);


  const getCategories = () => {

    setLoading(true);

    fetch(
      'https://backend.arihantauto.in' + "/api/get-all-categories")
      .then((res) => res.json())
      .then((json) => {
        setCategories(json.categories);
        setLoading(false);
      })

  };

  const getDisclaimerText = () => {

    setLoading(true);

    fetch(
      'https://backend.arihantauto.in' + "/api/get-disclaimer-text")
      .then((res) => res.json())
      .then((json) => {

        setDisclaimerText1(json.disclaimerText1);
        setDisclaimerText2(json.disclaimerText2);
        setDisclaimerText3(json.disclaimerText3);
        setDisclaimerText4(json.disclaimerText4);
        setDisclaimerText5(json.disclaimerText5);
        setDisclaimerText6(json.disclaimerText6);
        setDisclaimerText7(json.disclaimerText7);
        setDisclaimerText8(json.disclaimerText8);
        setDisclaimerText9(json.disclaimerText9);
        setDisclaimerText10(json.disclaimerText10);

        setLoading(false);
      })

  };

  const handleChangeSearch = (event) => {
    setSearchKey(event.target.value)
  }

  const openCategory = (category) => {
    setIsCategoriesVisible(false);
    setCurrentCategory(category);
    setCurrentCategoryId(category.id);
  }




  var filteredCategories = [];

  for (var i = 0; i < categories.length; i++) {

    if (categories[i].name.toLowerCase().includes(searchKey.toLowerCase())) {
      filteredCategories.push(categories[i]);
    }
  }


  return (

    <>

      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

        <Header />


        <div className="only-desktop" 
             style={{ flexDirection: 'row', marginTop: 60, marginBottom: 30, position:'fixed', 
                      top:0, left:0, right:0, zIndex:8000, backgroundColor:'#FCF5E5' }}>

          <Link to="/dashboard" style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center',
            width: '20%', borderRadius: 0, textDecoration: 'none', height: 60,
            cursor: 'pointer',
            alignSelf: 'center', borderBottom: '2px solid grey'
          }}>

            <span style={{
              width: '100%', textAlign: 'center',
              color: '#000000', fontSize: 15, textTransform: 'uppercase', fontWeight: 700
            }}>{strings.dashboard}</span>
          </Link>

          <Link to="/new-order" style={{
            display: 'flex', textDecoration: 'none', borderRadius: 0, flexDirection: 'row', cursor: 'pointer', alignItems: 'center', width: '20%', height: 60,
            alignSelf: 'center', borderBottom: '4px solid #F05225',
          }}>

            <span style={{
              width: '100%', color: '#F05225',
              textAlign: 'center', fontSize: 15, textTransform: 'uppercase', fontWeight: 700
            }}>{strings.new_order}</span>
          </Link>

          <Link to="/cart" style={{
            display: 'flex', textDecoration: 'none', borderRadius: 0, flexDirection: 'row', cursor: 'pointer', alignItems: 'center', width: '20%', height: 60,
            alignSelf: 'center', color: '#000000', borderBottom: '2px solid grey'
          }}>

            <span style={{ width: '100%', textAlign: 'center',
             fontSize: 15, textTransform: 'uppercase', fontWeight: 700 }}>{strings.cart}</span>
          </Link>

          <Link to="/past-orders" style={{
            display: 'flex', borderRadius: 0, textDecoration: 'none', flexDirection: 'row', cursor: 'pointer', alignItems: 'center', width: '20%', height: 60,
            alignSelf: 'center', color: '#000000', borderBottom: '2px solid grey'
          }}>

            <span style={{ width: '100%', textAlign: 'center', 
            fontSize: 15, textTransform: 'uppercase', fontWeight: 700 }}>{strings.past_orders}</span>
          </Link>

          <Link to="/account" style={{
            display: 'flex', borderRadius: 0, textDecoration: 'none',
             flexDirection: 'row', cursor: 'pointer', alignItems: 'center', width: '20%', height: 60,
            alignSelf: 'center', color: '#000000', borderBottom: '2px solid grey'
          }}>

            <span style={{
              width: '100%', textAlign: 'center',
              fontSize: 15, textTransform: 'uppercase', fontWeight: 700
            }}>{strings.account}</span>
          </Link>

        </div>


        <div className="main newOrder">
          {disclaimer == false ? (
            <>


        {loading?

        <Breathing/>
        :
          <div style={{marginTop:isMobile?20:100, overflowY:'scroll', overflowX:'hidden'}}> 
              <div className="text1" style={customStyle1}>
                <h3>{strings.disclaimer}</h3>
              </div>

              <div className="empty" style={customStyle}>

                <ul>

                  {disclaimerText1 &&
                    <li>
                     {disclaimerText1}
                    </li>
                  }

                  {disclaimerText2 &&
                    <li>
                     {disclaimerText2}
                    </li>
                  }

                  {disclaimerText3 &&
                    <li>
                     {disclaimerText3}
                    </li>
                  }

                  {disclaimerText4 &&
                    <li>
                     {disclaimerText4}
                    </li>
                  }

                  {disclaimerText5 &&
                    <li>
                     {disclaimerText5}
                    </li>
                  }

                  {disclaimerText6 &&
                    <li>
                     {disclaimerText6}
                    </li>
                  }

                  {disclaimerText7 &&
                    <li>
                     {disclaimerText7}
                    </li>
                  }

                  {disclaimerText8 &&
                    <li>
                     {disclaimerText8}
                    </li>
                  }

                  {disclaimerText9 &&
                    <li>
                     {disclaimerText9}
                    </li>
                  }

                  {disclaimerText10 &&
                    <li>
                     {disclaimerText10}
                    </li>

                  }
                </ul>
              </div>

              <div className="btn acceptContinue" style={{marginBottom:100}}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: '#F05225' }}
                  onClick={handleAccept}
                >
                  {strings.accept_and_continue}
                </Button>
              </div>

          </div>
}



            </>
          ) : (
            <>


              <Neworder2
                currentCategory={currentCategory}
                currentCategoryId={currentCategoryId}
                server={server}
                access_token={access_token} />

            </>
          )}
        </div>

      </div>

      <Box
        sx={{
          display: { xs: "block", md: "none", lg: "none" },
        }}
      >
        <BottomNavigation
          sx={{
            width: "100%",
            position: "fixed",
            bottom: 0,
            left: 0,
            zIndex:1000,
            backgroundColor:'#f0f0f0',
            right: 0,
          }}
          value={"1"}
          showLabels
          onChange={handleChange}
          elevation={3} s
        >
          <BottomNavigationAction
            label="DASHBOARD"
            value="0"
            sx={{fontSize:35, fontWeight:700}}
            icon={<DashboardIcon />}
          />
          <BottomNavigationAction
            label="NEW ORDER"
            value="1"
            sx={{fontSize:35, fontWeight:700}}
            icon={<AddBoxIcon />}
          />
          <BottomNavigationAction
            label="CART"
            value="2"
            sx={{fontSize:35, fontWeight:700}}
            icon={<ShoppingCartIcon />}
          />
          <BottomNavigationAction
            label="ORDERS"
            value="3"
            sx={{fontSize:35, fontWeight:700}}
            icon={<ShoppingBagIcon />}
          />          

        </BottomNavigation>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
  point: state.auth.point,
});

export default connect(mapStateToProps, {})(withRouter(Neworder));



//export default Neworder;
