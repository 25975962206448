import React, { Component, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../assets/scss/Login.scss";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { ToastContainer, toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';
import AddBoxIcon from "@mui/icons-material/AddBox";

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { Formik } from 'formik';
import * as yup from 'yup';
import axios from "axios";
import { strings } from "../string.js";

import SweetAlert from "react-bootstrap-sweetalert";
import 'react-toastify/dist/ReactToastify.css';
import logo from "../assets/img/arihant_logo.png";
import banner from "../assets/img/ordering.webp";

//Redux
import { connect } from "react-redux";
import { login, logout } from "../actions/authAction";
import store from "../store";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

let schema = yup.object({
  email: yup.string().required("Email is Required").nullable(),
  password: yup.string().required("Password is Required").nullable()
});

const Login = ({ history, server, login, role, auth, logout }) => {

  const [alert, setAlert] = useState(null);
  const [checked, setChecked] = React.useState(false);
  const [onLoading, setOnLoading] = React.useState(false);
  const [loginError, setLoginError] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);

  const handleCheck = () => {
    checked ? setChecked(false) : setChecked(true);
  }

  const classes = useStyles();

  const hideAlert = () => {
    setAlert(null);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };  


  const alertcall = (errormessage) => {
    setAlert(
      <SweetAlert
        title="ERROR !"
        onConfirm={hideAlert}
      >
        {errormessage}
      </SweetAlert>
    )
  }

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (!error.response) {

        toast.error("Network Error", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      } else {
        if (error.response.status === 401) {
          logout();
          history.push("/");
        }
        return Promise.reject(error);
      }
    }
  );

  useEffect(() => {


    if (auth) {

      console.log('aoaoa'+role.id)

      if (role.id === 1) {
        

        // This is admin user
        localStorage.setItem("user_type", "admin");
        history.push("/dashboard");
      }
      else if (role.id === 2) {

        localStorage.setItem("user_type", "user");
        history.push("/dashboard");
      }
      else if (role.id === 3) {

        localStorage.setItem("user_type", "user");
        history.push("/dashboard");
      }
    }
  }, []);


  // Validate email
  function validateEmail(email) {

    var regx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (regx.test(email)) {
      return true;
    } else {
      return false;
    }
  }


  const handleLogin = (values) => {


    if (values.email == null || values.password == null || values.email.length == 0 || values.password.length == 0) {
      setLoginError('Please enter email and password');
    } 
    //else if (!validateEmail(values.email)) {
    //  setLoginError('Please enter a valid email');
   // } 
    else {

      setLoginError('');
      if (checked) {
        localStorage.setItem("email", values.email);
        localStorage.setItem("password", values.password);
      }

      const bodyParameters = {
        email: values.email,
        password: values.password
      };

      setOnLoading(true);

      axios
        .post('https://backend.arihantauto.in/' + "api/auth/login", bodyParameters)
        .then(response => {
          login(response.data);
          
          if (response.data.role[0].id === 1) {

            // This is admin user
            localStorage.setItem("user_type", "admin");
            history.push("/dashboard");
          }
          else if (response.data.role[0].id === 2) {

            localStorage.setItem("user_type", "user");
            history.push("/dashboard");
          }
          else if (response.data.role[0].id === 3) {
            localStorage.setItem("user_type", "user");
            history.push("/dashboard");
          }
          else if (response.data.role[0].id === 4) {
            localStorage.setItem("user_type", "user");
            history.push("/dashboard");
          }

          setOnLoading(false);
        })
        .catch(function (error) {

          setOnLoading(false);
          if ((JSON.stringify(error)).includes('400')) {
            setLoginError('Incorrect email or password')
          }

        });
    }



  };

  return (

    <div className={classes.page} style={{backgroundColor: "#C0C0C0"}}>
      {alert}
      <div className={classes.formContainer} 
         style={{backgroundColor:'white', width:isMobile?'80%':400}}>
        <Formik
          initialValues={{
            email: localStorage.getItem("email"),
            password: localStorage.getItem("password")
          }}
          onSubmit={(values) => {
            handleLogin(values);
          }}>
          {({ handleChange, handleBlur, values, handleSubmit, isValid, errors, touched }) => (
            <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>

              <img src={logo} width="300" style={{textAlign:'center', width:isMobile?'100%':300}}/>

              <h3 style={{ marginTop: 0, textTransform: 'uppercase', color:'#212529' }}>User Login</h3>
              <TextField className={classes.text} id="standard-basic" label="Email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                type="email"
                value={values.email} />



              <TextField className={classes.text} id="standard-basic" 
                label="Password" 
                type={showPassword==true?"text":"password"}
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  endAdornment: showPassword==false? 
                  <VisibilityIcon onClick={togglePasswordVisibility}/>:
                  <VisibilityOffIcon onClick={togglePasswordVisibility}/>
                }}
                value={values.password} />                

              <p style={{
                color: "red", marginTop: 0,
                fontSize: 11, marginLeft: 45, textAlign: 'left'
              }}>{loginError}</p>


              <div style={{ textAlign: "left", marginLeft: isMobile?0:32, fontSize: 12 }}>
                <Checkbox
                  checked={checked}
                  onChange={handleCheck}
                />
                <span style={{color:'#212529', fontWeight:500}}>Remember Me</span>
              </div>

              <Button style={{
                margin: 20, width: "150px", backgroundColor: '#F05225', color: 'white',
                paddingTop: 10, fontWeight: 600
              }}
                variant="contained"
                color="white"
                type='submit'
              >
                {onLoading ? <CircularProgress style={{ color: 'white' }} /> : "LOGIN"}
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};



const mapStateToProps = state => ({
  server: state.auth.server,
  auth: state.auth.auth,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
});

export default connect(
  mapStateToProps,
  { login, logout } //mapActionToProps
)(Login);

const useStyles = makeStyles({
  root: {
    padding: 10,
    color: "black",
  },
  text: {
    width: "250px",
    margin: 10,
    color: "black",
    fontFamily:'Muli'
  },
  page: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: !isMobile ? `url(${banner})` : 'none',
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundOpacity: 0.2,
    textAlign: "center",
    margin: "auto",
    minHeight: "100vh",
    minWidth: "100vw",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    marginTop: isMobile ? -100 : 0,
    background: "#c3a2c3",
    padding: "25px 25px",
    width: "400px",
    textAlign: "center",
    backdropFilter: "blur(16px) saturate(180%)",
    // -webkit-backdrop-filter: blur(16px) saturate(180%);s
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    borderRadius: "12px",
    border: isMobile ? 0 : "1px solid rgba(209, 213, 219, 0.3)",
  },
});