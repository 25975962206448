import React, { useState, useEffect } from "react";
import "./Style.css";
import UpcomingProducts from "./UpcomingProducts";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import "./Modal.css";
import { Rating } from "@mui/material";
import { Grid, Typography } from "@material-ui/core";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import LatestProducts from "./LatestProducts";
import LastOrders from "./LastOrders";
import { strings } from "../../string.js";
import { connect } from "react-redux";
import { logout } from "../../actions/authAction";
import { Link, withRouter } from "react-router-dom";
//import logo from "../../assets/img/arihant_logo.jpeg";
//import Switch from "@material-ui/core/Switch";
import { isMobile } from 'react-device-detect';
import logo from "../../assets/img/arihant_logo.png";
import hindiEnglish from "../../assets/img/hi-en.png";
import PersonIcon from "@mui/icons-material/Person";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import Switch from "react-switch";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Select from 'react-select';
import ReactModal from 'react-modal-resizable-draggable';
import ReactPlayer from 'react-player'


const useStyles = makeStyles({
  card: {
    backgroundColor: "#fff",
    borderRadius: "5px",
    marginBottom: "10px",
    padding: "25px",
    boxShadow: "0 1.2rem 3.2rem rgba(0,0,0,0.3)",
  },
});

const Header = ({ history, server, access_token, login, role, auth, logout }) => {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isChecked, setIsChecked] = React.useState(false);
  const [onLoading, setOnLoading] = React.useState(false);
  const [customers, setCustomers] = React.useState([]);
  const [customerId, setCustomerId] = React.useState(0);

  const [langValue, setLangValue] = React.useState('en');

  const [isVideoModalOpen, setIsVideoModalOpen] = React.useState(false);


  const classes = useStyles();

  useEffect(() => {

    var langu = localStorage.getItem('lang');

    if (!langu) {

      strings.setLanguage('en');
      //setIsChecked(false)
      setLangValue('en')

    } else {

      if (langu == 'en') {
        strings.setLanguage('en');
        //setIsChecked(false)
        setLangValue('en')
      } else {
        strings.setLanguage('hi');
        //setIsChecked(true)
        setLangValue('hi')
      }
    }

    var userType = localStorage.getItem("user_type");

    if(userType == 'admin'){
        getCustomers();
    }

  }, []);


  const openVideoModal = () => {

    setIsVideoModalOpen(true);
  }

  const closeVideoModal = () => {

    setIsVideoModalOpen(false)

  }


  const getCustomers = (e) => {

    setOnLoading(true)

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + access_token
      }
    };

    fetch('https://backend.arihantauto.in/' + 'api/user/get-customers', requestOptions)
      .then(response => response.json())
      .then(data => setTheCustomers(data.customers));

  }


  const handleChange = (checked) => {

    if (checked) {
      strings.setLanguage('hi')
      setIsChecked(true)
      localStorage.setItem('lang', 'hi');
      window.location.reload();
    } else {
      strings.setLanguage('en')
      setIsChecked(false)
      localStorage.setItem('lang', 'en');
      window.location.reload();
    }

  }

  const handleLanguageChange = (e) => {
    
      strings.setLanguage(e.target.value)
      setLangValue(e.target.value);
      //setIsChecked(true)
      localStorage.setItem('lang', e.target.value);
      window.location.reload();        
  }


  const setTheCustomers = (theCustomers) => {

    setCustomers(theCustomers);
    setCustomerId(theCustomers[0].id)
  }

  const switchChanged = (e) => {

    if (e.target.checked) {
      strings.setLanguage('hi')
      setIsChecked(true)
      localStorage.setItem('lang', 'hi');
      window.location.reload();
    } else {
      strings.setLanguage('en')
      setIsChecked(false)
      localStorage.setItem('lang', 'en');
      window.location.reload();
    }
  }

  const handleLogOut = () => {

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };
    const parameter = {
      _method: "DELETE",
    };

    logout();
    localStorage.removeItem('disclaimer_accpeted');
   // localStorage.clear();

    history.push("/");

  };


  /*const changeCustomer = (event) => {

    setCustomerId(event.target.value);
    localStorage.setItem("customer_id", event.target.value)
  }*/

  const changeCustomer = (selectedOption) => {
    
    setCustomerId(selectedOption.value);
    localStorage.setItem("customer_id", selectedOption.value);
  };


  var userType = localStorage.getItem("user_type");

  return (
    <>

                <ReactModal 
                    initWidth={800} 
                    initHeight={500} 
                    onFocus={() => console.log("Modal is clicked")}
                    className={"my-modal-custom-class"}
                    onRequestClose={closeVideoModal} 
                    disableResize
                    isOpen={isVideoModalOpen}>

                    <h3 style={{marginLeft:10}}>Video on How to Use the Website</h3>
                    <div className="body">                        
                        <ReactPlayer
                         width="100%"
                         controls={true}                         
                         url='https://backend.arihantauto.in/video.mp4' />
                    </div>
                    <button onClick={closeVideoModal} style={{display:'none'}}>
                        Close
                    </button>

                </ReactModal>

      {!isMobile ?
        <div style={{
          bgcolor: "#ffffff", position: 'fixed', width: '100%', zIndex: 10000, top: 0,
          backgroundColor: '#ffffff'
        }}>

          <div container justifyContent="center" alignItems="center" style={{
            display: 'flex', flex: 1,
            flexDirection: 'row'
          }}>

            <div xs style={{ cursor: 'pointer' }}>
              <img src={logo} width="200" style={{ marginTop: 5 }} />
            </div>

            <div>

              {userType == 'admin' &&
                <div style={{ display:'flex', flexDirection: 'row',
                 alignItems:'center', justifyContent:'space-between', alignSelf: 'center', marginLeft: 550, marginTop: 20 }}>

                  <span style={{ color: 'red' }}>Admin User Logged In - </span>

                  <label style={{marginLeft:10}}>{strings.ordering_for_customer}</label>                  

                <div style={{ marginLeft: 20, width:350}}>
                  <Select
                      className="form-control"                      
                      options={customers.map(customer => ({ value: customer.id, label: `${customer.name} (${customer.email})` }))}
                      onChange={changeCustomer}
                      isSearchable
                      value={customerId}
                      placeholder={
                        customerId
                          ? customers.find(customer => customer.id === customerId)?.name
                          : 'Select a customer to place orders for...'
                      }

                  />

                </div>
                </div>
              }
             

              <div
                style={{ color: '#212529', display: 'flex', flexDirection: 'row' }}
              >


                <div style={{
                  marginTop: 5,
                  position: 'absolute',
                  right: 105,
                  top: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}>

                <button style={{float:'right', marginRight:100, 
                backgroundColor:'#5190ED', padding:13, color:'white', fontSize:15, borderWidth:0, borderRadius:10}} 
                        onClick={openVideoModal}>Watch Video</button>         

                  <img src={hindiEnglish} 
                       width="30" 
                       style={{marginRight:5}} />

                  <select style={{padding:5, marginRight:20}} onChange={handleLanguageChange} value={langValue}>
                      <option value="en">{strings.english}</option>
                      <option value="hi">{strings.hindi}</option>
                  </select>

                </div>

                <a
      onClick={handleLogOut}
      style={{
        fontSize: 15,
        marginLeft: 50,
        position: 'absolute',
        right: 10,
        top: 20,
        cursor: 'pointer',
        paddingRight: 10,
        display: 'flex', // Use flex display
        alignItems: 'center', // Align items vertically center
        justifyContent:'center'
      }}
    >
      <ExitToAppIcon style={{ marginRight: 2 }} />
      <span>{strings.logout}</span>
    </a>

              </div>

            </div>
          </div>
        </div>
        :

        <div style={{
          display: 'flex', flexDirection: 'column',
          position: 'fixed', zIndex: 10000, width: '100%', paddingBottom: 10,
          backgroundColor: '#ffffff', borderBottom: '1px solid rgba(0,0,0,.125)'
        }}>

          <div style={{ display: 'flex', flex: 1, flexDirection: 'row', width: '100%' }}>
            <img src={logo} width="200" style={{ marginTop: 5 }} />

            <Link to="/account" style={{
              marginTop: 20
            }}>

              <AccountCircleIcon
                fontSize="large"
                sx={{ width: 30, height: 30, color: '#F05225' }}
                style={{ position: 'absolute', fontSize: "80px", right: 20 }} />
            </Link>

          </div>



          <div style={{ display: 'flex', flexDirection: 'row' }}>

            <div style={{
              marginLeft: 10, marginRight: 10, display: 'flex',
              flexDirection: 'row',
              width: '100%', color: '#212529', alignSelf: 'flex-start',
            }}>

              <div style={{
                marginTop: 5,
                display: 'flex',
                alignItems: 'center',
              }}>
                <span style={{ marginRight: 5 }}>{strings.english}</span>
                <Switch
                  onChange={handleChange}
                  checked={isChecked}
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={15}
                  width={40}
                  className="react-switch"
                  id="material-switch"
                />
                <span style={{ marginLeft: 5 }}>{strings.hindi}</span>
              </div>

              <a onClick={handleLogOut}
                style={{
                  fontSize: 12, marginLeft: '45%', marginTop: 12,
                  cursor: 'pointer', paddingRight: 5
                }}>
                {strings.logout}</a>

            </div>
          </div>
        </div>
      }

    </>

  );
};


const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
  point: state.auth.point,
});

export default connect(mapStateToProps, { logout })(withRouter(Header));
