import React, { Fragment, useEffect, useState } from "react";

import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import { makeStyles } from "@material-ui/core";
import Login from "./Login/Login";
import Register from "./Register/Register";
import PrivateRoute from "./PrivateRoute";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import factory from "./store";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import UnavailableItemsEntry from './UnavailableItemsEntry';

import Main from "./Pages/Main/Main";
import NewOrder from "./Pages/Neworder/Neworder";
import Account from "./Pages/Acoounts/Account";
import Cart from "./Pages/Ongoing/Cart";
import OrderPreview from "./Pages/Neworder/NewOrder4";
import PastOrders from "./Pages/PastOrders/PastOrders";
import Home from "./Pages/Home/Home";
import { strings } from "./string.js";
import Mychoice from "./Pages/Mychoice/Mychoice.js";
import Notifications from "./Pages/Main/Notifications";
import { string } from "yup/lib/locale";


const { store, persistor } = factory();

const hist = createBrowserHistory();

const useStyles = makeStyles({
  container: {
    display: "flex",
  },
});

const App = () => {  
  

  const classes = useStyles({

  });    

  const [cacheCleared, setCacheCleared] = useState(false);

  
  global.backendUrl = 'https://backend.arihantauto.in';  
 
  // Function to clear cache for the current website
  const clearCurrentWebsiteCache = async () => {
    try {
      const cacheNames = await caches.keys();
      await Promise.all(
        cacheNames
          .filter(name => new URL(name).origin === window.location.origin)
          .map(name => caches.delete(name))
      );
      console.log("Website Cache Cleared");
      setCacheCleared(true);
      // Save in localStorage that cache has been cleared
      localStorage.setItem("cacheCleared", "true");
    } catch (error) {
      console.error("Error clearing cache:", error);
    }
  };


  useEffect(() => {
    const hasClearedCache = localStorage.getItem("cacheCleared") === "true";

    if (!hasClearedCache && !cacheCleared) {
      // Clear cache only once when the user opens the website for the first time
      clearCurrentWebsiteCache();
    }
  }, [cacheCleared]);

  return (
    <>
      <Provider store={store}>
        <ToastContainer position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover/>

        <PersistGate loading={null} persistor={persistor}>
          <Router history={hist}>
            <Switch>
              {/* SideBar Routes */}
              <Route path="/" component={Login} exact={true} />
              <Route path="/register" component={Register} exact={true} />
              <PrivateRoute path="/dashboard" component={Home} exact={true} />
              <PrivateRoute path="/new-order" component={NewOrder} exact={true} />
              <PrivateRoute path="/mychoices" component={Mychoice} exact={true} />
              <PrivateRoute path="/cart" component={Cart} exact={true} />
              <PrivateRoute path="/order-preview" component={OrderPreview} exact={true} />
              <PrivateRoute path="/past-orders" component={PastOrders} exact={true} />
              <PrivateRoute path="/account" component={Account} exact={true} />
              <PrivateRoute path="/notifications" component={Notifications} exact={true} />
            </Switch>
          </Router>
        </PersistGate>

        

      </Provider>


    </>
  );
};

export default App;
