import React, { Component, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../assets/scss/Login.scss";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { ToastContainer, toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';
import AddBoxIcon from "@mui/icons-material/AddBox";

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { Formik } from 'formik';
import * as yup from 'yup';
import axios from "axios";
import { strings } from "../string.js";

import SweetAlert from "react-bootstrap-sweetalert";
import 'react-toastify/dist/ReactToastify.css';
import logo from "../assets/img/arihant_logo.png";
import check from "../assets/img/check.png";
import banner from "../assets/img/ordering.jpg";

//Redux
import { connect } from "react-redux";
import { login, logout } from "../actions/authAction";
import store from "../store";
import { MultiSelect } from "react-multi-select-component";
import Switch from "react-switch";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

let schema = yup.object({
    email: yup.string().required("Email is Required").nullable(),
    password: yup.string().required("Password is Required").nullable()
});

const Register = ({ history, server, login, role, auth, logout }) => {

    const [alert, setAlert] = useState(null);
    const [checked, setChecked] = React.useState(false);
    const [onLoading, setOnLoading] = React.useState(false);
    const [registerError, setRegisterError] = React.useState('');

    const [billingName, setBillingName] = React.useState('');
    const [ownerName, setOwnerName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [whatsappNumber, setWhatsappNumber] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [district, setDistrict] = React.useState('');
    const [state, setState] = React.useState('');
    const [pincode, setPincode] = React.useState('');
    const [gstNumber, setGstNumber] = React.useState('');

    const [vehicleBrand, setVehicleBrand] = React.useState('');
    const [dealerType, setDealerType] = React.useState('Authorized Main Dealer');
    const [mainDealerName, setMainDealerName] = React.useState('');

    const [billingNameEmpty, setBillingNameEmpty] = React.useState(false);
    const [ownerNameEmpty, setOwnerNameEmpty] = React.useState(false);
    const [emailEmpty, setEmailEmpty] = React.useState(false);
    const [emailInvalid, setEmailInvalid] = React.useState(false);
    const [whatsappNumberEmpty, setWhatsappNumberEmpty] = React.useState(false);
    const [phoneNumberEmpty, setPhoneNumberEmpty] = React.useState(false);
    const [phoneDigitExceed, setPhoneDigitExceed] = React.useState(false);
    const [whatsappDigitExceed, setWhatsappDigitExceed] = React.useState(false);
    const [phoneDigitInsufficient, setPhoneDigitInsufficient] = React.useState(false);
    const [whatsappDigitInsufficient, setWhatsappDigitInsufficient] = React.useState(false);
    const [addressEmpty, setAddressEmpty] = React.useState(false);
    const [districtEmpty, setDistrictEmpty] = React.useState(false);
    const [stateEmpty, setStateEmpty] = React.useState(false);
    const [vehicleBrandEmpty, setVehicleBrandEmpty] = React.useState(false);
    const [dealerTypeEmpty, setDealerTypeEmpty] = React.useState(false);

    const [userTypeEmpty, setUserTypeEmpty] = React.useState(false);

    const [userType, setUserType] = useState('');
    const [selected, setSelected] = useState([]);

    const [isChecked, setIsChecked] = React.useState(false);
    const [success, setSuccess] = React.useState(false);

    const [postSubmitMessage, setPostSubmitMessage] = React.useState('');

    const applicableFor = [
        { label: 'Hero', value: 12 },
        { label: 'Honda', value: 13 },
        { label: 'TVS', value: 15 },
        { label: 'Bajaj', value: 14 },
        { label: 'Yamaha', value: 17 },
        { label: 'Suzuki', value: 19 },
        { label: 'Electric', value: 18 },
    ]


    const handleDealerTypeChange = (event) => {
       
        setDealerType(event.target.value);
    };


    const handleChange = (checked) => {

        if (checked) {
            strings.setLanguage('hi')
            setIsChecked(true)
            localStorage.setItem('lang', 'hi');            
        } else {
            strings.setLanguage('en')
            setIsChecked(false)
            localStorage.setItem('lang', 'en');            
        }
    }

    useEffect(() => {

        var langu = localStorage.getItem('lang');

        if (!langu) {

            strings.setLanguage('en');
            setIsChecked(false)

        } else {

            if (langu == 'en') {
                strings.setLanguage('en');
                setIsChecked(false)
            } else {
                strings.setLanguage('hi');
                setIsChecked(true)
            }
        }

    }, []);


    // Function to handle select change
    const handleUserTypeChange = (e) => {
        setUserType(e.target.value);
    };


    useEffect(() => {

        if(userType !==''){
            setUserTypeEmpty(false);
        }

    }, [userType]);


    useEffect(() => {

        if(phoneNumber.length >= 10){
            setPhoneDigitInsufficient(false);
        }

    }, [phoneNumber]);


    useEffect(() => {

        if(whatsappNumber.length >= 10){
            setWhatsappDigitInsufficient(false);
        }

    }, [whatsappNumber]);



    const handleButtonClick = () => {

       setPostSubmitMessage('')

        var allGood = true;

        if (userType == '') {

            setUserTypeEmpty(true);
            allGood = false;

        }else if (userType == 'Shopkeeper') {

            if (billingName == '') {

                setBillingNameEmpty(true);
                allGood = false;

            }else{
                setBillingNameEmpty(false);
            }

            if (ownerName == '') {

                setOwnerNameEmpty(true);
                allGood = false;

            }else{
                setOwnerNameEmpty(false);
            } 

            if (email == '') {

                setEmailEmpty(true);
                allGood = false;

            }else{
                setEmailEmpty(false);
            } 

            if (email !== '' && !validateEmail(email)) {

                setEmailInvalid(true);
                allGood = false;

            }else{
                setEmailInvalid(false);
            }

            if (whatsappNumber == '') {

                setWhatsappNumberEmpty(true);
                allGood = false;
            }else{
                setWhatsappNumberEmpty(false);
            }
            
            if (whatsappNumber !== '' && whatsappNumber.length < 10) {

                setWhatsappDigitInsufficient(true);
                allGood = false;

            }else{
                setWhatsappDigitInsufficient(false);
            } 

            if (phoneNumber == '') {

                setPhoneNumberEmpty(true);
                allGood = false;

            }else{
                setPhoneNumberEmpty(false);
            }

            if (phoneNumber !== '' && phoneNumber.length < 10) {

                setPhoneDigitInsufficient(true);
                allGood = false;

            }else{
                setPhoneDigitInsufficient(false);
            } 

            if (address == '') {

                setAddressEmpty(true);
                allGood = false;

            }else{
                setAddressEmpty(false);
            } 

            if (district == '') {

                setDistrictEmpty(true);
                allGood = false;

            }else{
                setDistrictEmpty(false);
            } 

            if (state == '') {

                setStateEmpty(true);
                allGood = false;
            }else{
                setStateEmpty(false);
            } 


        } else {
            // Showroom
            if (billingName == '') {

                setBillingNameEmpty(true);
                allGood = false;

            }
            if (ownerName == '') {

                setOwnerNameEmpty(true);
                allGood = false;

            }
            if (email == '') {

                setEmailEmpty(true);
                allGood = false;

            }
            if (email !== '' && !validateEmail(email)) {

                setEmailInvalid(true);
                allGood = false;

            }
            if (whatsappNumber == '') {

                setWhatsappNumberEmpty(true);
                allGood = false;

            }
            if (whatsappNumber !== '' && whatsappNumber.length < 10) {

                setWhatsappDigitInsufficient(true);
                allGood = false;

            }
            if (phoneNumber == '') {

                setPhoneNumberEmpty(true);
                allGood = false;

            }
            if (phoneNumber !== '' && phoneNumber.length < 10) {

                setPhoneDigitInsufficient(true);
                allGood = false;

            }
            if (address == '') {

                setAddressEmpty(true);
                allGood = false;

            }
            if (district == '') {

                setDistrictEmpty(true);
                allGood = false;

            }
            if (state == '') {

                setStateEmpty(true);
                allGood = false;

            }
            if (selected.length == 0) {

                setVehicleBrandEmpty(true);
                allGood = false;

            }
            if (dealerType == '') {

                setDealerTypeEmpty(true);
                allGood = false;

            } 
        }

        if (allGood == true) {
            // Call the register API
            callRegisterAPI();
        }

    };

    const handleBackClick = () => {
        setSuccess(false);
    };

    const handleCheck = () => {
        checked ? setChecked(false) : setChecked(true);
    }
    const classes = useStyles();

    const hideAlert = () => {
        setAlert(null);
    };

    const alertcall = (errormessage) => {
        setAlert(
            <SweetAlert
                title="ERROR !"
                onConfirm={hideAlert}
            >
                {errormessage}
            </SweetAlert>
        )
    }  


    // Validate email
    function validateEmail(email) {

        var regx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if (regx.test(email)) {
            return true;
        } else {
            return false;
        }
    }

    const handleInputChange = (e) => {

        const { name, value } = e.target; // Destructure the name and value from the input element

        switch (name) {

            case 'billingName':
                setBillingName(value);

                if(value.length>0){
                    setBillingNameEmpty(false);
                }
                break;
            case 'ownerName':
                setOwnerName(value);
                break;

                if(value.length>0){
                    setOwnerNameEmpty(false);
                }

            case 'email':    

                setEmail(value);

                if(value.length>0){
                    setEmailEmpty(false);
                }

                if(validateEmail(value)){
                  setEmailInvalid(false);
                }

                break;
            case 'whatsappNumber':

                if(value.length <= 10){
                    setWhatsappNumber(value);
                }else{
                    setWhatsappDigitExceed(true);
                }

                if(value.length>0){
                    setWhatsappNumberEmpty(false);
                }

                break;
            case 'phoneNumber':
                
                    if(value.length <= 10){
                        setPhoneNumber(value);
                    }else{
                        setPhoneDigitExceed(true);
                    }

                    if(value.length>0){
                        setPhoneNumberEmpty(false);
                    }

                    break;
                
            case 'address':
                setAddress(value);
                if(value.length>0){
                    setAddressEmpty(false);
                }
                break;
            case 'district':
                setDistrict(value);
                if(value.length>0){
                    setDistrictEmpty(false);
                }
                break;
            case 'state':
                setState(value);
                if(value.length>0){
                    setStateEmpty(false);
                }
                break;
            case 'pincode':
                setPincode(value);
                break;
            case 'gstNumber':
                setGstNumber(value);
                break;
            case 'vehicleBrand':
                setVehicleBrand(value);
                break;
            case 'dealerType':
                setDealerType(value);
                break;
            case 'mainDealerName':
                setMainDealerName(value);
                break;

            default:
                // Handle other input fields if needed
                break;
        }
    };



    const callRegisterAPI = (values) => {

        setWhatsappDigitExceed(false);
        setPhoneDigitExceed(false);

        var applicableForTemp = [];

        for (var i = 0; i < selected.length; i++) {

            applicableForTemp.push(selected[i].value)
        }

        const bodyParameters = {
            billing_name: billingName,
            whatsapp_number: whatsappNumber,
            phone_number: phoneNumber,
            email: email,
            owner_name: ownerName,
            address: address,
            district: district,
            state: state,
            pincode: pincode,
            gst_number: gstNumber,
            dealer_type: dealerType,
            main_dealer_name: mainDealerName,
            applicable_for: applicableForTemp.toString(),
            user_type:userType
        };

        setOnLoading(true);    
        
        const csrfToken = window.csrfToken;

        axios
            .post('https://backend.arihantauto.in/' + "api/auth/login", bodyParameters)
            .then(response => {

                if(response.data.hasOwnProperty('message')){
                    
                    setPostSubmitMessage(response.data.message);

                }else{

                    // Reset all values
                    setBillingName('');
                    setOwnerName('');
                    setEmail('');
                    setWhatsappNumber('');
                    setPhoneNumber('');
                    setAddress('');
                    setDistrict('');
                    setState('');
                    setPincode('');
                    setGstNumber('');
                    setVehicleBrand('');
                    setDealerType('');
                    setMainDealerName('');
                    setSelected([]);

                    // Reset all error messages
                    setBillingNameEmpty(false);
                    setOwnerNameEmpty(false);
                    setEmailEmpty(false);
                    setEmailInvalid(false);
                    setWhatsappNumberEmpty(false);
                    setPhoneNumberEmpty(false);
                    setPhoneDigitExceed(false);
                    setWhatsappDigitExceed(false);
                    setPhoneDigitInsufficient(false);
                    setWhatsappDigitInsufficient(false);
                    setAddressEmpty(false);
                    setDistrictEmpty(false);
                    setStateEmpty(false);
                    setVehicleBrandEmpty(false);
                    setDealerTypeEmpty(false);
                    setUserTypeEmpty(false);

                    setSuccess(true);
                }                

                setOnLoading(false);
            })
            .catch(function (error) {

                setOnLoading(false);
                console.log('error', error)

            });

    };



    return (

        <div className={classes.page} style={{ backgroundColor: "#ffffff" }}>

            {alert}

            {success ?
                <>
                    <img src={check} width="200" />
                    <h2>{strings.thank_you_for_registering}</h2>

                    <Button style={{
                        margin: 20, width: 200, backgroundColor: '#F05225', color: 'white',
                        paddingTop: 10, fontWeight: 600
                    }}
                        variant="contained"
                        color="white"
                        type='submit'
                        onClick={handleBackClick}
                    >
                        {onLoading ? <CircularProgress style={{ color: 'white' }} /> : "BACK"}
                    </Button>
                </>
                :

                <div className={classes.formContainer}
                    style={{ backgroundColor: '#F4F4FA', width: isMobile ? '90%' : '50%', marginLeft: isMobile ? '0%' : 0, }}>

                    <img src={logo} width="300" style={{ textAlign: 'center', width: isMobile ? '80%' : 300, alignSelf: 'center', marginTop: isMobile ? 100 : 0 }} />

                    <div style={{
                        marginTop: 5,
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center'
                    }}>
                        <span style={{ marginRight: 5 }}>{strings.english}</span>
                        <Switch
                            onChange={handleChange}
                            checked={isChecked}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={15}
                            width={40}
                            className="react-switch"
                            id="material-switch"
                        />
                        <span style={{ marginLeft: 5 }}>{strings.hindi}</span>
                    </div>


                    <h2>{strings.register_yourself_as}</h2>

                    <select className="form-control" onChange={handleUserTypeChange}
                        value={userType} style={{ width: isMobile ? '80%' : 400, alignSelf: 'center', height: 40, fontSize: 15, display:'none' }}>
                        <option value="Shopkeeper">{strings.register_shopkeeper}</option>
                        <option value="Showroom">{strings.register_showroom}</option>
                    </select>

                    <div className="form-control">
    <label>
        <input 
            type="radio"
            name="userType"
            value="Shopkeeper"
            checked={userType === "Shopkeeper"}
            onChange={handleUserTypeChange}
        />
        {strings.register_shopkeeper}
    </label>

    <label style={{marginLeft:isMobile?0:20}}>
        <input
            type="radio"
            name="userType"
            value="Showroom"
            checked={userType === "Showroom"}
            onChange={handleUserTypeChange}
        />
        {strings.register_showroom}
    </label>
</div>

{userTypeEmpty == true &&

   <span style={{color:'red'}}>
       Please select user type
   </span>
}

                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>

                        <div style={{ width: isMobile ? '90%' : '45%', alignSelf: 'center', fontSize: 15, marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <label>{userType == 'Shopkeeper' ? strings.register_billing_name_shopkeeper : strings.register_billing_name_showroom}<span style={{ color: 'red' }}>*</span></label>
                            <input
                                type="text"
                                name="billingName"
                                value={billingName}
                                onChange={handleInputChange}
                                placeholder={userType == 'Shopkeeper' ? strings.register_billing_name_shopkeeper : strings.register_billing_name_showroom}
                                style={{ height: 40, width: '100%', marginTop: 5, fontSize: 15, padding: 5, borderRadius: 5, outline: 'none' }}
                            />

                            {billingNameEmpty &&
                                <span style={{ color: 'red', marginTop: 5 }}>{userType == 'Shopkeeper' ? strings.register_billing_name_shopkeeper_is_mandatory : strings.register_billing_name_showroom_is_mandatory}</span>
                            }

                        </div>


                        <div style={{ width: isMobile ? '90%' : '45%', alignSelf: 'center', fontSize: 15, marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <label>{userType == 'Shopkeeper' ? strings.register_owner_name_shopkeeper : strings.register_owner_name_showroom}<span style={{ color: 'red' }}>*</span></label>
                            <input
                                type="text"
                                name="ownerName"
                                value={ownerName}
                                onChange={handleInputChange}
                                placeholder={userType == 'Shopkeeper' ? strings.register_owner_name_shopkeeper : strings.register_owner_name_showroom}
                                style={{ height: 40, width: '100%', marginTop: 5, fontSize: 15, padding: 5, borderRadius: 5, outline: 'none' }}
                            />
                            {ownerNameEmpty &&
                                <span style={{ color: 'red', marginTop: 5 }}>{userType == 'Shopkeeper' ? strings.register_owner_name_shopkeeper_is_mandatory : strings.register_owner_name_showroom_is_mandatory}</span>
                            }
                        </div>

                        <div style={{ width: isMobile ? '90%' : '45%', alignSelf: 'center', fontSize: 15, marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <label>{strings.register_email_id}<span style={{ color: 'red' }}>*</span></label>
                            <input
                                type="text"
                                name="email"
                                value={email}
                                onChange={handleInputChange}
                                placeholder={strings.register_email_id}
                                style={{ height: 40, width: '100%', marginTop: 5, fontSize: 15, padding: 5, borderRadius: 5, outline: 'none' }}
                            />

                            {emailEmpty &&
                                <span style={{ color: 'red', marginTop: 5 }}> {strings.register_email_id_is_mandatory} </span>
                            }

                            {emailInvalid &&
                                <span style={{ color: 'red', marginTop: 5 }}> {strings.register_email_id_is_invalid} </span>
                            }
                        </div>


                        <div style={{ width: isMobile ? '90%' : '45%', alignSelf: 'center', fontSize: 15, marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <label>{strings.register_whatsapp_number}<span style={{ color: 'red' }}>*</span></label>
                            <input
                                type="text"
                                name="whatsappNumber"
                                value={whatsappNumber}
                                onChange={handleInputChange}
                                placeholder={strings.register_whatsapp_number}
                                style={{ height: 40, width: '100%', marginTop: 5, fontSize: 15, padding: 5, borderRadius: 5, outline: 'none' }}
                            />
                            {whatsappNumberEmpty &&
                                <span style={{ color: 'red', marginTop: 5 }}> {strings.register_whatsapp_number_is_mandatory} </span>
                            }

                            {whatsappDigitExceed &&    
                                <span style={{ color: 'red', marginTop: 5 }}> {strings.register_whatsapp_digit_exceed} </span>
                            }
                            
                            {whatsappDigitInsufficient &&
                                <span style={{ color: 'red', marginTop: 5 }}> {strings.register_whatsapp_digit_insufficient} </span>
                            }
                        </div>

                        <div style={{ width: isMobile ? '90%' : '45%', alignSelf: 'center', fontSize: 15, marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <label>{strings.register_phone_number}<span style={{ color: 'red' }}>*</span></label>
                            <input
                                type="text"
                                name="phoneNumber"
                                value={phoneNumber}
                                onChange={handleInputChange}
                                placeholder={strings.register_phone_number}
                                style={{ height: 40, width: '100%', marginTop: 5, fontSize: 15, padding: 5, borderRadius: 5, outline: 'none' }}
                            />
                            {phoneNumberEmpty &&
                                <span style={{ color: 'red', marginTop: 5 }}> {strings.register_phone_number_is_mandatory} </span>
                            }

                            {phoneDigitExceed &&
                                <span style={{ color: 'red', marginTop: 5 }}> {strings.register_phone_digit_exceed} </span>
                            }

                            {phoneDigitInsufficient &&
                                <span style={{ color: 'red', marginTop: 5 }}> {strings.register_phone_digit_insufficient} </span>
                            }
                        </div>


                        <div style={{ width: isMobile ? '90%' : '45%', alignSelf: 'center', fontSize: 15, marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <label >{strings.register_address}<span style={{ color: 'red' }}>*</span></label>
                            <input
                                type="text"
                                name="address"
                                value={address}
                                onChange={handleInputChange}
                                placeholder={strings.register_address}
                                style={{ height: 40, width: '100%', marginTop: 5, fontSize: 15, padding: 5, borderRadius: 5, outline: 'none' }}
                            />
                            {addressEmpty &&
                                <span style={{ color: 'red', marginTop: 5 }}> {strings.register_address_is_mandatory} </span>
                            }
                        </div>


                        <div style={{ width: isMobile ? '90%' : '45%', alignSelf: 'center', fontSize: 15, marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <label >{strings.register_district}<span style={{ color: 'red' }}>*</span></label>

                            <input
                                type="text"
                                name="district"
                                value={district}
                                onChange={handleInputChange}
                                placeholder={strings.register_district}
                                style={{ height: 40, width: '100%', marginTop: 5, fontSize: 15, padding: 5, borderRadius: 5, outline: 'none' }}
                            />

                            {districtEmpty &&
                                <span style={{ color: 'red', marginTop: 5 }}> {strings.register_district_is_mandatory} </span>
                            }
                        </div>


                        <div style={{ width: isMobile ? '90%' : '45%', alignSelf: 'center', fontSize: 15, marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <label >{strings.register_state}<span style={{ color: 'red' }}>*</span></label>
                            <input
                                type="text"
                                name="state"
                                value={state}
                                onChange={handleInputChange}
                                placeholder={strings.register_state}
                                style={{ height: 40, width: '100%', marginTop: 5, fontSize: 15, padding: 5, borderRadius: 5, outline: 'none' }}
                            />
                            {stateEmpty &&
                                <span style={{ color: 'red', marginTop: 5 }}> {strings.register_state_is_mandatory} </span>
                            }
                        </div>


                        <div style={{ width: isMobile ? '90%' : '45%', alignSelf: 'center', fontSize: 15, marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <label >{strings.register_pincode}</label>
                            <input
                                type="text"
                                name="pincode"
                                value={pincode}
                                onChange={handleInputChange}
                                placeholder={strings.register_pincode}
                                style={{ height: 40, width: '100%', marginTop: 5, fontSize: 15, padding: 5, borderRadius: 5, outline: 'none' }}
                            />

                        </div>

                        <div style={{ width: isMobile ? '90%' : '45%', alignSelf: 'center', fontSize: 15, marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <label>{strings.register_gst_number}</label>
                            <input
                                type="text"
                                name="gstNumber"
                                value={gstNumber}
                                onChange={handleInputChange}
                                placeholder={strings.register_gst_number}
                                style={{ height: 40, width: '100%', marginTop: 5, fontSize: 15, padding: 5, borderRadius: 5, outline: 'none' }}
                            />
                        </div>

                        {userType == 'Showroom' &&
                            <div style={{ width: isMobile ? '90%' : '45%', alignSelf: 'center', fontSize: 15, marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <label>{strings.register_vehicle_brand}<span style={{ color: 'red' }}>*</span></label>
                                <MultiSelect
                                    options={applicableFor}
                                    value={selected}
                                    onChange={setSelected}
                                    labelledBy="Select Vehicle Brand"
                                    style={{ height: 40, width: '100%', marginTop: 5, fontSize: 15, padding: 5, borderRadius: 5, }}

                                />

                                {vehicleBrandEmpty &&
                                    <span style={{ color: 'red', marginTop: 5 }}> {strings.register_vehicle_brand_is_mandatory} </span>
                                }
                            </div>
                        }

                        {userType == 'Showroom' &&

                            <div style={{ width: isMobile ? '90%' : '45%', alignSelf: 'center', fontSize: 15, marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <label>{strings.register_dealer_type}<span style={{ color: 'red' }}>*</span></label>
                                <select placeholder="Dealer Type"
                                        onChange={handleDealerTypeChange}  
                                        style={{ height: 40, width: '100%', marginTop: 5, fontSize: 15, padding: 5, borderRadius: 5 }}>
                                    <option value="Authorized Main Dealer">{strings.register_authorized_main_dealer}</option>
                                    <option value="Associate Dealer">{strings.register_associate_dealer}</option>
                                    <option value="Authorized Representative of Dealer">{strings.register_authorized_representative_of_dealer}</option>
                                </select>

                                {dealerTypeEmpty &&
                                    <span style={{ color: 'red', marginTop: 5 }}> {strings.register_dealer_type_is_mandatory} </span>
                                }
                            </div>
                        }


                        {userType == 'Showroom' &&

                            <div style={{ width: isMobile ? '90%' : '45%', alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <label>{strings.register_name_of_the_main_dealer}</label>
                                <input
                                    type="text"
                                    name="mainDealerName"
                                    value={mainDealerName}
                                    onChange={handleInputChange}
                                    placeholder={strings.register_name_of_the_main_dealer}
                                    style={{ height: 40, width: '100%', marginTop: 5, fontSize: 15, padding: 5, borderRadius: 5, outline: 'none' }}
                                />

                            </div>
                        }


                        <div style={{ width: isMobile ? '90%' : '100%', alignSelf: 'center', fontSize: 15, marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                            <Button style={{
                                margin: 20, width: '90%', backgroundColor: '#F05225', color: 'white',
                                paddingTop: 10, fontWeight: 600
                            }}
                                variant="contained"
                                color="white"
                                type='submit'
                                onClick={handleButtonClick}
                            >
                                {onLoading ? <CircularProgress style={{ color: 'white' }} /> : "REGISTER"}
                            </Button>

                            <span style={{color:'red', marginTop:10}}>{postSubmitMessage}</span>


                        </div>
                    </div>
                </div>
            }
        </div>
    );
};



const mapStateToProps = state => ({
    server: state.auth.server,
    auth: state.auth.auth,
    access_token: state.auth.access_token,
    role: state.auth.role[0],
});

export default connect(
    mapStateToProps,
    { login, logout } //mapActionToProps
)(Register);

const useStyles = makeStyles({
    root: {
        padding: 10,
        color: "black",
    },
    text: {
        width: "250px",
        margin: 10,
        color: "black",
        fontFamily: 'Muli'
    },
    page: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundOpacity: 0.2,
        textAlign: "center",
        margin: "auto",
        minHeight: "100vh",
        minWidth: "100vw",
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        borderRadius: 10,
        marginTop: isMobile ? -100 : 0,
        background: "#c3a2c3",
        padding: "25px 25px",
        width: "400px",
        textAlign: "center",
        backdropFilter: "blur(16px) saturate(180%)",
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        borderRadius: "12px",
        border: isMobile ? 0 : "1px solid rgba(209, 213, 219, 0.3)",
    },
});