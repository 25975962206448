import React from "react";
//import Carousel from "react-elastic-carousel";
import Grid from "@mui/material/Grid";
import { strings } from "../../string.js";
import { Image, Breathing } from 'react-shimmer'
import { isMobile } from 'react-device-detect';
import ReactStars from "react-rating-stars-component";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';
import placeholder from "../../assets/img/placeholder.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const customStyle = {

  margin: "0px",
  padding: isMobile?0:"10px 10px",
  border: "2px solid #EEEEEE",
  backgroundColor: "#fff",
  boxShadow: "0 1.4rem 3.8rem rgba(0, 0, 0, 0.075)",
  alignContent: "center",
  borderRadius: 10,
  width: isMobile ? '100%' : 200,
  height: isMobile?'100%':270,
  marginBottom:15,
  display: 'flex',
  flexDirection: 'column',
  marginTop:isMobile?0:15,
  marginLeft:isMobile?0:15,
}

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 4 },
  { width: 768, itemsToShow: 4 },
  { width: 1200, itemsToShow: 4 },
];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

class LatestProducts extends React.Component {

  constructor(props) {

    super(props);

    this.state = {

      items: [
        {
          id: 1,
          url: "https://rukminim1.flixcart.com/image/416/416/khmbafk0-0/helmet/x/t/m/sbh-17-terminator-full-face-graphic-helmet-in-matt-black-fluo-original-imafxhedgn57jgvd.jpeg?q=70",
        },
        {
          id: 2,
          url: "https://rukminim1.flixcart.com/image/612/612/kdt50nk0-0/helmet/o/a/9/crux-of-open-face-crx-of-r-open-face-vega-original-imafuhyuc7vvr7hh.jpeg?q=70",
        },
        {
          id: 3,
          url: "https://rukminim1.flixcart.com/image/612/612/jtiz0cw0/helmet/c/x/x/off-road-1-or-d-v-dk-58-motocross-vega-original-imafevfhm4uttsyj.jpeg?q=70",
        },
        {
          id: 4,
          url: "https://rukminim1.flixcart.com/image/612/612/khmbafk0-0/helmet/w/o/5/sbh-17-terminator-full-face-graphic-helmet-in-fluo-matt-black-original-imafxhe9pbxukrht.jpeg?q=70",
        },
        {
          id: 5,
          url: "https://rukminim1.flixcart.com/image/612/612/kapoo7k0/helmet/h/z/w/new-1-824-56-open-face-nivia-original-imafs82jfadh3rut.jpeg?q=70",
        },
      ],
      latestProducts: [],
      isLoading: false,
      open: false,
      currentProduct: null,
    };

    this.showArrows = this.showArrows.bind(this);
    this.hideArrows = this.hideArrows.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.carouselRef = React.createRef();
  }

  showArrows() {
    this.setState({ areArrowsVisible: true })
  }

  hideArrows() {
    this.setState({ areArrowsVisible: false })
  }


  componentDidMount() {
    this.getLatestProducts();
  };


  handleClose() {
    this.setState({ open: false })
  }

  showModal(item) {

    this.setState({ currentProduct: item })
    this.setState({ open: true })
  }


  getLatestProducts() {

    this.setState({ isLoading: true })

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + this.props.access_token
      }
    };


    fetch(
      'https://backend.arihantauto.in' + "/api/user/get-latest-products", requestOptions)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ latestProducts: json.products })
        this.setState({ isLoading: false })

      })
  };



  render() {

    var theProducts = [];
    var addedProductNames = [];

    for (var i = 0; i < this.state.latestProducts.length; i++) {

      if (!addedProductNames.includes(this.state.latestProducts[i].name)) {
        theProducts.push(this.state.latestProducts[i]);


        if (strings.getLanguage() == 'en') {
          addedProductNames.push(this.state.latestProducts[i].name);
        } else {
          addedProductNames.push(this.state.latestProducts[i].name);
        }

      }
    }


    const { items } = this.state;

    const onNextStart = (currentItem, nextItem) => {
      if (currentItem.index === nextItem.index) {
        // we hit the last item, go to first item
        this.carouselRef.current.goTo(0);
      }
    };
    const onPrevStart = (currentItem, nextItem) => {
      if (currentItem.index === nextItem.index) {
        // we hit the first item, go to last item
        this.carouselRef.current.goTo(theProducts.length);
      }
    };

    return (
      <>

        <div style={{padding:10}}>
          {this.state.isLoading ?
            <Breathing width={'100%'} height={150} />
            :

            <div onMouseEnter={this.showArrows} onMouseLeave={this.hideArrows} 
            style={{width:'98%', marginLeft:'1%', borderRadius:5 }}>

              <Carousel
                enableAutoPlay
                showArrows={true}
                ref={this.carouselRef}
                onPrevStart={onPrevStart}
                infinite={true}
                responsive={responsive}
                onNextStart={onNextStart}
                disableArrowsOnEnd={false}
                breakPoints={breakPoints}>
                {theProducts.map((item) => (
                  <div key={item.id} style={customStyle}>
                    <img className="square"
                      style={{ width: isMobile ? '100%' : 200, maxHeight: 200, alignSelf: 'center',
                    objectFit:'contain', marginTop:5 }}
                      src={item.product_image_path!==null?'https://backend.arihantauto.in' + '/' + item.product_image_path:placeholder} 
                      height={156} width={156} alt="" />

                    <span style={{
                      textAlign: 'center', fontSize: 16, marginTop: 15,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                      {strings.getLanguage() == 'en' ? item.name : item.name_in_hindi}
                    </span>
                    
                    <span style={{ textAlign: 'center', fontSize: 12 }}>₹{item.price}</span>
                    <button className="btn9"
                      onClick={() => this.showModal(item)}
                      style={{ alignSelf: 'center', borderRadius:5,
                      marginLeft: 0, backgroundColor:'#F05225' }}>{strings.view_details}</button>
                  </div>
                ))}
              </Carousel>

            </div>
          }

          <Modal
            open={this.state.open}
            onClose={this.handleClose}
            style={{zIndex:50000}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            {this.state.currentProduct &&
              <div className="background">
                <div className="modal-container" style={{ width: '100%' }}>


                  <CloseIcon style={{ float: 'right', marginRight:-20, marginTop:-5 }} 
                  onClick={this.handleClose} />

                  <div className="main modalProduct">

                    <img className="square"
                      style={{ width: isMobile ? '100%' : 200, textAlign: 'center', objectFit:'contain' }}
                      src={this.state.currentProduct.product_image_path!==null?'https://backend.arihantauto.in' + '/'
                       + this.state.currentProduct.product_image_path:placeholder} 
                      alt="" height={256} width={256} />


                    <div className="modalRightArea"
                      style={{ display: 'flex', flexDirection: 'column' }}>
                      <div className="text-modal">
                        <h2 style={{ lineHeight: 1, paddingBottom: 0, marginBottom: 0 }}>

                          {strings.getLanguage() == 'en' ? this.state.currentProduct.name : this.state.currentProduct.name_in_hindi}

                        </h2>
                      </div>

                      <div className="ratings" style={{marginLeft:3}}>
                        <ReactStars
                          count={5}
                          size={24}
                          value={this.state.currentProduct.rating_in_stars}
                          isHalf={true}
                          edit={false}
                          activeColor="#ffd700"
                        />
                      </div>


                      <div className="Buttons" style={{ marginTop: 10 }}>
                        <button className="cat">{this.state.currentProduct.category.name}</button>
                        <button className="brand ">{this.state.currentProduct.brand.name}</button>
                      </div>
                      <div className="text-modal">
                        <h4><b>Product Description</b></h4>
                        <span>
                          {this.state.currentProduct.description}
                        </span>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            }
          </Modal>

        </div>
      </>
    );
  }
}

export default LatestProducts;
